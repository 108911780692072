import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { LogoCustomFillComponent } from '@modules/shared/logo-custom-fill/logo-custom-fill.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { User } from '@models/users/user.model';
import { AuthService } from '@services/auth/auth.service';
import { DateAdapter } from '@angular/material/core';
import { environment as env } from '@environments/environment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MatMenuModule,
    LogoCustomFillComponent,
  ],
  providers: [ScreenWidthService],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  @Input() showLogo = false;
  @Input() bodyColor?: string;
  @Input() backgroundColor?: string;
  @Input() showOptions?: boolean = true;
  @Input() includePoweredBy?: boolean = false;
  @Input() isEventFooter = false;
  isDesktop?: boolean;
  loggedUser?: User;
  selectedLanguage: string = 'APP.LANGUAGE.ENGLISH';
  languageData = [
    {
      label: 'APP.LANGUAGE.ENGLISH',
      value: 'en',
    },
    {
      label: 'APP.LANGUAGE.GERMAN',
      value: 'de',
    },
  ];

  constructor(
    private screenWidthService: ScreenWidthService,
    private translateService: TranslateService,
    private authService: AuthService,
    private dateAdapter: DateAdapter<any>,
  ) {
    this.trackUserChanges();
    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe((isDesktop) => {
        this.isDesktop = isDesktop;
      });

    const currentLang = this.languageData.find(
      (languaage) => languaage.value === this.translateService.currentLang,
    );

    if (currentLang) {
      this.selectedLanguage = currentLang.label;
    }
  }

  onMenuEvent(languageSelected: string) {
    this.selectedLanguage = languageSelected;

    const selectedLangData = this.languageData.find(
      (languaage) => languaage.label === this.selectedLanguage,
    );
    if (selectedLangData) {
      if (this.loggedUser && this.loggedUser.id > 0) {
        this.authService
          .updateUser({
            id: this.loggedUser.id,
            language: selectedLangData.value,
          })
          .subscribe(() =>
            localStorage.setItem('userLang', selectedLangData.value),
          );
      } else {
        localStorage.setItem('userLang', selectedLangData.value);
      }

      this.translateService.use(selectedLangData.value);
      this.dateAdapter.setLocale(selectedLangData.value);
    }
  }

  private trackUserChanges() {
    this.authService.userSubject
      .pipe(takeUntilDestroyed())
      .subscribe((user) => {
        this.loggedUser = user;
      });
  }

  get userTheme(): string {
    const userTheme = localStorage.getItem('userTheme');

    if (userTheme) {
      return userTheme;
    }

    return 'light';
  }

  protected env = env;
}
