@if(isWeddingPage){
  <svg [attr.height]='height' viewBox="0 0 705 114" [attr.fill]=fillColor xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M193.779 83.0944C193.127 83.0445 192.442 82.5284 191.732 81.5712C190.98 80.564 190.596 79.0408 190.596 77.0598V0H181.024V30.4311C176.814 28.5166 172.579 27.5428 168.436 27.5428C158.755 27.5428 151.371 30.3228 146.485 35.8081C141.632 41.26 139.167 49.0509 139.167 58.956C139.167 76.577 147.186 85.708 163.015 86.0992C167.659 86.0992 171.76 85.0338 175.185 82.9363C177.632 81.438 179.604 79.765 181.074 77.9255L181.141 79.0159C181.224 80.5973 182.185 82.1622 183.981 83.6521C185.743 85.1254 188.299 85.8745 191.565 85.8745H199.283V83.3108H195.023L193.779 83.0861V83.0944ZM153.685 37.5477C156.934 32.5452 161.653 30.1148 168.102 30.1148C171.677 30.1148 176.029 31.1885 181.032 33.3193V72.2738C180.832 74.6376 179.002 77.1264 175.611 79.6651C172.161 82.2371 167.968 83.5439 163.132 83.5439C153.443 83.5439 148.74 75.5033 148.74 58.9643C148.74 49.8 150.402 42.6001 153.685 37.5477Z" [attr.fill]=fillColor />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M112.154 27.5428C118.502 27.5428 123.923 29.4656 128.275 33.2611L128.258 33.2778C132.644 37.0983 134.866 42.8 134.866 50.208V52.164H94.5044C94.329 53.9286 94.2455 55.7598 94.2455 57.6243C94.2455 66.6887 95.8409 73.4058 98.9899 77.5843C102.122 81.7544 106.132 83.777 111.244 83.777C118.628 83.777 125.285 82.6533 131.023 80.4559L131.775 80.1646L132.769 82.4785L131.967 82.7865C125.886 85.1338 119.045 86.324 111.302 86.324C103.559 86.324 97.1607 83.9768 92.2826 79.3489C87.3961 74.7126 84.9153 67.4961 84.9153 57.9489C84.9153 48.4018 87.4964 40.8689 92.5916 35.5585C97.7036 30.2397 104.277 27.5428 112.154 27.5428ZM94.8482 49.5754H125.527C125.352 43.0247 124.015 38.1055 121.543 34.9592C118.97 31.6963 115.721 30.1065 111.703 30.1065C107.685 30.1065 104.043 31.9044 100.878 35.4503C97.822 38.878 95.7927 43.6279 94.8482 49.5754Z" [attr.fill]=fillColor />
    <path d="M82.0671 29.7235C82.6267 30.5725 82.9608 31.3383 83.0945 32.0458V32.0541C83.2198 32.7033 83.2782 33.3276 83.2782 33.8936C83.2782 36.0161 82.8021 38.2052 81.8583 40.4026L61.093 85.8828H58.8461L43.1009 43.7154L23.8558 85.8828H21.6089L0 28.0005H9.68099L26.4452 73.1976L41.7059 40.1446L37.2372 27.9922H46.9098L63.674 73.1976L79.1937 39.7118C80.054 38.2135 80.4717 36.3907 80.4717 34.443C80.4717 32.4952 79.9705 31.0802 78.9347 30.1064L78.3083 29.5154L80.0206 27.809L80.5636 28.0837C81.0063 28.3168 81.474 28.8162 82.0671 29.7235Z" [attr.fill]=fillColor />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M253.226 81.5712C253.936 82.5284 254.621 83.0445 255.272 83.0944L255.281 83.0861L256.525 83.3108H260.785V85.8745H253.067C249.801 85.8745 247.245 85.1254 245.483 83.6521C243.687 82.1622 242.726 80.5973 242.643 79.0159L242.576 77.9255C241.106 79.765 239.135 81.438 236.687 82.9363C233.263 85.0338 229.161 86.0992 224.517 86.0992C208.68 85.708 200.661 76.577 200.661 58.956C200.661 49.0509 203.125 41.26 207.978 35.8081C212.865 30.3228 220.249 27.5428 229.93 27.5428C234.081 27.5428 238.308 28.5166 242.518 30.4311V0H252.09V77.0598C252.09 79.0492 252.474 80.564 253.226 81.5712ZM229.587 30.1148C223.139 30.1148 218.42 32.5452 215.17 37.5477C211.888 42.6001 210.225 49.8084 210.225 58.9643C210.225 75.5033 214.936 83.5439 224.626 83.5439C229.454 83.5439 233.647 82.2371 237.097 79.6651C240.496 77.1264 242.317 74.6376 242.518 72.2738V33.3193C237.514 31.1885 233.171 30.1148 229.587 30.1148Z" [attr.fill]=fillColor />
    <path d="M277.433 83.0943C276.865 83.0444 276.196 82.52 275.486 81.5711C274.735 80.5639 274.35 79.0407 274.35 77.0597V27.9921H264.778V77.1097L264.895 79.0158C264.979 80.5972 265.939 82.1621 267.735 83.652C269.497 85.1253 272.053 85.8744 275.319 85.8744H283.037V83.3107H278.769L277.424 83.086L277.433 83.0943Z" [attr.fill]=fillColor />
    <path d="M273.707 16.8385C272.613 17.8873 271.26 18.42 269.689 18.42C268.111 18.42 266.774 17.8873 265.714 16.8302C264.653 15.7731 264.118 14.433 264.118 12.8682C264.118 11.3034 264.653 9.95493 265.705 8.86454C266.766 7.75751 268.111 7.19983 269.689 7.19983C271.268 7.19983 272.621 7.75751 273.724 8.8479C274.818 9.94661 275.378 11.295 275.378 12.8682C275.378 14.4414 274.81 15.7814 273.707 16.8385Z" [attr.fill]=fillColor />
    <path d="M336.805 83.0945C336.153 83.0446 335.468 82.5285 334.758 81.5713C334.007 80.5641 333.622 79.0492 333.622 77.0599V41.8677C333.622 38.0222 332.612 34.8177 330.607 32.3372C329.304 30.7974 327.249 29.5904 324.509 28.7581C321.845 27.9507 318.37 27.5428 314.169 27.5428C309.967 27.5428 305.841 29.0244 302.249 31.946C299.827 33.9187 297.906 36.0079 296.502 38.2054V27.9923H286.93V85.8746H296.502V45.1306C296.502 42.3755 298.357 39.1542 302.007 35.5501C305.665 31.946 309.833 30.1148 314.394 30.1148C316.591 30.1148 318.437 30.4644 319.865 31.1386C322.638 32.6202 324.05 36.1994 324.05 41.7595V77.1098L324.167 79.0159C324.251 80.5974 325.211 82.1623 327.007 83.6522C328.769 85.1255 331.325 85.8746 334.591 85.8746H342.309V83.3109H338.05L336.805 83.0862V83.0945Z" [attr.fill]=fillColor />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M348.182 35.8082C353.068 30.3229 360.452 27.5428 370.133 27.5428V27.5512C377.191 27.5512 384.458 28.8163 391.734 31.3051L392.293 31.4965V86.4073C392.21 104.719 384.032 114 367.986 114C359.241 114 351.648 111.128 345.425 105.46L344.732 104.827L346.778 103.079L347.321 103.529C353.603 108.773 360.561 111.428 367.995 111.428C377.91 111.428 382.729 103.238 382.729 86.399V78.0005C381.259 79.8067 379.305 81.4631 376.891 82.9447C373.466 85.0339 369.365 86.0993 364.72 86.0993C348.883 85.7081 340.865 76.5771 340.865 58.9561C340.865 49.051 343.329 41.2684 348.182 35.8082ZM369.791 30.1148C363.342 30.1148 358.623 32.5453 355.373 37.5478C352.091 42.6002 350.429 49.8084 350.429 58.9644C350.429 75.5034 355.14 83.544 364.829 83.544C369.657 83.544 373.85 82.2372 377.3 79.6652C380.699 77.1265 382.52 74.6377 382.721 72.2738V33.2112C377.651 31.1553 373.307 30.1148 369.791 30.1148Z" [attr.fill]=fillColor />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M429.697 27.5428C425.145 27.5428 421.01 29.1659 417.41 32.3622C414.996 34.5097 413.083 36.7654 411.697 39.1043V27.984H402.124V113.975H411.697V84.1849C415.907 85.5999 419.716 86.3157 423.048 86.3157C443.221 86.3157 453.444 75.8197 453.444 55.1272C453.444 36.8153 445.459 27.5345 429.706 27.5345L429.697 27.5428ZM411.688 46.221C411.972 43.1995 413.944 39.7119 417.561 35.8664C421.144 32.0542 425.187 30.1148 429.589 30.1148C439.203 30.1148 443.872 38.2636 443.872 55.0273C443.872 74.363 437.023 83.7687 422.931 83.7687C419.724 83.7687 415.94 82.9863 411.697 81.4381V46.2293L411.688 46.221Z" [attr.fill]=fillColor />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M501.282 81.5713C502 82.5285 502.685 83.0446 503.336 83.0945H503.32L504.564 83.3192H508.824V85.8829H504.489C499.085 85.8829 495.46 84.8424 493.396 82.7033C491.701 81.0885 490.774 79.6402 490.598 78.3167C486.731 83.6355 481.059 86.3324 473.692 86.3324C463.777 86.3324 458.072 81.7794 456.727 72.8065C456.577 71.8909 456.493 70.9254 456.493 69.9266C456.493 68.9277 456.652 67.8124 456.97 66.6221C457.32 65.3319 458.239 63.9918 459.801 62.5186C462.825 59.6553 469.165 57.9572 479.163 57.3413C483.515 56.9334 487.366 56.9334 490.565 57.1582V38.3302C490.565 37.656 490.565 36.9901 490.231 36.1661C489.846 35.2172 489.32 34.26 488.685 33.3444C488.109 32.512 487.015 31.7629 485.444 31.1137C483.824 30.4478 481.694 30.1065 478.996 30.1065C476.298 30.1065 473.091 30.6059 469.457 31.5964C465.824 32.5869 463.134 33.5691 461.472 34.5014L460.745 34.9092L459.425 32.6119L460.252 32.229C467.026 29.116 474.018 27.5428 481.026 27.5428C489.095 27.5428 494.324 28.9911 496.997 31.9793C499.085 34.3016 500.146 36.7987 500.146 39.3956V77.0599C500.146 79.0409 500.53 80.5641 501.282 81.5713ZM475.505 83.7604C479.272 83.7604 482.713 82.4453 485.737 79.8566L485.728 79.8483C488.744 77.2763 490.373 74.7959 490.573 72.4819V59.7302C488.819 59.622 487.04 59.5637 485.261 59.5637C483.231 59.5637 481.193 59.6802 479.205 59.8966C473.725 60.5459 470.142 61.7445 468.555 63.4591C466.985 65.1655 466.183 67.5793 466.183 70.6507C466.183 71.2167 466.216 71.8743 466.291 72.5901C466.935 80.1146 469.95 83.7604 475.505 83.7604Z" [attr.fill]=fillColor />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M538.352 27.5428C528.671 27.5428 521.287 30.3229 516.4 35.8082C511.547 41.2601 509.083 49.051 509.083 58.9561C509.083 76.5771 517.102 85.7081 532.931 86.0993C537.575 86.0993 541.676 85.0339 545.101 82.9363C547.515 81.4548 549.469 79.7983 550.939 77.9838V86.3823C550.939 103.221 546.12 111.411 536.205 111.411C528.771 111.411 521.813 108.756 515.532 103.512L514.989 103.063L512.942 104.811L513.635 105.443C519.858 111.112 527.451 113.983 536.197 113.983C552.242 113.983 560.42 104.703 560.504 86.3906V31.4965L559.944 31.3051C552.668 28.8163 545.401 27.5512 538.352 27.5512V27.5428ZM523.6 37.5478C526.85 32.5453 531.569 30.1148 538.018 30.1148C541.534 30.1148 545.878 31.1553 550.948 33.2112V72.2738C550.747 74.6377 548.926 77.1265 545.527 79.6652C542.077 82.2372 537.884 83.544 533.048 83.544C523.358 83.544 518.656 75.5034 518.656 58.9644C518.656 49.8001 520.318 42.6002 523.6 37.5478Z" [attr.fill]=fillColor />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M593.322 27.5428C599.67 27.5428 605.091 29.4656 609.443 33.2611L609.451 33.2695C613.837 37.09 616.059 42.7917 616.059 50.1997V52.1557H575.697C575.522 53.912 575.438 55.7432 575.438 57.616C575.438 66.6804 577.026 73.3975 580.175 77.576C583.315 81.7461 587.316 83.7687 592.428 83.7687C599.812 83.7687 606.469 82.645 612.208 80.4476L612.96 80.1563L613.962 82.4702L613.152 82.7865C607.071 85.1338 600.238 86.324 592.487 86.324C584.735 86.324 578.337 83.9768 573.459 79.3489C568.572 74.7126 566.092 67.4961 566.092 57.9489C566.092 48.4018 568.673 40.8689 573.768 35.5585C578.871 30.2397 585.454 27.5428 593.322 27.5428ZM576.015 49.5837H606.687V49.5754C606.52 43.0247 605.183 38.1138 602.711 34.9592C600.138 31.6963 596.889 30.1065 592.871 30.1065C588.853 30.1065 585.211 31.9044 582.046 35.4503C578.988 38.8796 576.959 43.6323 576.015 49.5837Z" [attr.fill]=fillColor />
    <path d="M624.203 77.4677C622.966 77.4677 621.906 77.8922 621.07 78.7246C620.235 79.557 619.809 80.6057 619.809 81.8459C619.809 83.0862 620.252 84.1516 621.129 85.0172C621.997 85.8912 623.05 86.3324 624.261 86.3324C625.472 86.3324 626.516 85.8912 627.393 85.0172C628.27 84.1516 628.713 83.0945 628.713 81.8959C628.713 80.6973 628.27 79.6485 627.393 78.7745C626.516 77.9089 625.447 77.4677 624.211 77.4677H624.203Z" [attr.fill]=fillColor />
    <path d="M699.395 83.0862L700.74 83.3109L700.732 83.3026H705V85.8663H697.282C694.008 85.8663 691.452 85.1171 689.689 83.6439C687.902 82.1539 686.941 80.5891 686.857 79.0076L686.741 77.1015V27.984H696.313V77.0516C696.313 79.0409 696.697 80.5558 697.449 81.563C698.159 82.5119 698.827 83.0362 699.395 83.0862Z" [attr.fill]=fillColor />
    <path d="M691.644 18.42C693.214 18.42 694.567 17.8873 695.661 16.8385C696.772 15.7814 697.332 14.4414 697.332 12.8682C697.332 11.295 696.772 9.94661 695.678 8.8479C694.576 7.75751 693.222 7.19983 691.644 7.19983C690.065 7.19983 688.72 7.75751 687.659 8.86454C686.607 9.95493 686.072 11.3034 686.072 12.8682C686.072 14.433 686.607 15.7731 687.668 16.8302C688.729 17.8873 690.065 18.42 691.644 18.42Z" [attr.fill]=fillColor />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M676.592 81.5629C677.31 82.5202 677.995 83.0362 678.647 83.0862H678.63L679.875 83.3109H684.135V85.8746H679.799C674.395 85.8746 670.77 84.8341 668.707 82.6949C667.011 81.0802 666.084 79.6319 665.909 78.3084C662.041 83.6272 656.37 86.324 649.002 86.324C639.088 86.324 633.383 81.771 632.038 72.7982C631.887 71.8826 631.804 70.9171 631.804 69.9182C631.804 68.9194 631.963 67.804 632.28 66.6138C632.631 65.3236 633.55 63.9835 635.112 62.5102C638.135 59.6469 644.475 57.9489 654.474 57.333C658.825 56.9251 662.676 56.9168 665.875 57.1498V38.3219C665.875 37.6477 665.875 36.9818 665.541 36.1577C665.157 35.2089 664.631 34.2516 663.996 33.336C663.419 32.5037 662.325 31.7546 660.755 31.1053C659.134 30.4394 657.004 30.0982 654.307 30.0982C651.609 30.0982 648.401 30.5976 644.767 31.5881C641.134 32.5786 638.444 33.5608 636.782 34.493L636.055 34.9009L634.736 32.6036L635.563 32.2207C642.337 29.1077 649.328 27.5345 656.336 27.5345C664.405 27.5345 669.634 28.9828 672.307 31.971C674.395 34.2933 675.456 36.7903 675.456 39.3873V77.0516C675.456 79.0326 675.84 80.5558 676.592 81.5629ZM650.823 83.752C654.59 83.752 658.032 82.4369 661.056 79.8483L661.047 79.84C664.063 77.268 665.691 74.7875 665.892 72.4736V59.7218C664.138 59.6136 662.359 59.5554 660.58 59.5554C658.55 59.5554 656.512 59.6719 654.524 59.8883C649.044 60.5375 645.461 61.7361 643.874 63.4508C642.303 65.1572 641.502 67.571 641.502 70.6424C641.502 71.2084 641.535 71.866 641.61 72.5818C642.253 80.1063 645.269 83.752 650.823 83.752Z" [attr.fill]=fillColor />
  </svg>
}

